<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col></v-col>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <v-btn
                variant="tonal"
                color="primary"
                icon=""
                @click="addNew()"
              >
                <v-icon icon="mdi-plus-thick" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Add New
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedWorkflowType"
                label="Workflow Types"
                density="compact"
                auto-select-first
                clearable
                :items="workflowTypeLookup"
                :item-title="item => item.WorkflowTypeName"
                :item-value="item => item.WorkflowTypeID"
              >
              </v-autocomplete>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      v-model:search="search"
      :headers="workflowListHeaders"
      :items="filteredItems"
      item-value="WorkflowNotificationID"
      items-per-page="100"
      >
      <template v-slot:item.WorkflowTypeID="{ item }">
        <v-icon
        v-if="[1, 2, 3].includes(item.WorkflowTypeID)"
          size="sm"
          icon="mdi-account-plus"
          color="primary"
        ></v-icon>
        <v-icon
          v-if="[4, 5, 6].includes(item.WorkflowTypeID)"
          size="sm"
          icon="mdi-account-minus"
          color="primary"
        ></v-icon>
        {{ item.WorkflowTypeName }}
      </template>
      <template v-slot:item.AssignedUserName="{ item }">
        <v-btn
          v-if="item.AssignedUserEmailAddress"
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.AssignedUserEmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.AssignedUserEmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ !!item.AssignedUserName ? item.AssignedUserName : (item.RoleID ? "IT" : item.RoleID) }}
      </template>
      <template v-slot:item.DueDate="{ item }">
        {{ formattedDate(item.DueDate) }}
        <v-btn
          v-if="isOverdue(item.DueDate)"
          variant="text"
          size="m"
          color="error"
          icon=""
        ><v-icon icon="mdi-alert" />
          <v-tooltip
            activator="parent"
            location="top"
          >This workflow is overdue
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.RequestedByUserName="{ item }">
        <v-btn
          v-if="!!item.RequestedByUserEmailAddress"
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.RequestedByUserEmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.RequestedByUserEmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ item.RequestedByUserName }}
      </template>
      <template v-slot:item.UserName="{ item }">
        <v-btn
          v-if="!!item.EmailAddress"
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.EmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.EmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ item.UserName }}
      </template>
      <template v-slot:item.LastUpdated="{ item }">
        {{ formattedDate(item.LastUpdated) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            @click="routeToUserProfile(item.UserID)"
          ><v-icon size="sm" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View {{  item.UserName }}
            </v-tooltip>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!item.AssignedUserEmailAddress"
            icon=""
            variant="plain"
            density="compact"
            @click="sendReminder(item.WorkflowNotificationID)"
          ><v-icon size="sm" icon="mdi-hand-pointing-right" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send Reminder to {{  item.AssignedUserEmailAddress }}
            </v-tooltip>
          </v-btn>
          <v-btn
              color="primary"
              icon=""
              variant="plain"
              density="compact"
              @click="showAssignWorkflow(item.WorkflowNotificationID, item.AssignedUserID)"
            ><v-icon size="sm" icon="mdi-clipboard-account" />
              <v-tooltip
                activator="parent"
                location="top"
              >Assign
              </v-tooltip>
            </v-btn>
        </div>
      </template>
    </v-data-table>

  <v-dialog v-model="showDialogAssignItem" width="750px">
    <v-card>
      <v-card-title>Assign Workflow</v-card-title>
      <v-card-text>
        <v-row><v-col></v-col></v-row>
        <v-row>
          <v-col class="pa-0 ma-1"><v-autocomplete
            class=""
            density="compact"
            persistent-placeholder
            variant="underlined"
            label="New Assignee"
            v-model="editItem.UserID"
            :items="usersList"
            :item-title="item => `${item.Name} (${item.CompanyCode})`"
            :item-value="item => item.UserID"
          /></v-col>
        </v-row>
      </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="assignWorkflow(editItem.WorkflowNotificationID)"
      >Save</v-btn>
      <v-btn
        color="error"
        @click="cancelAssignWorkflow()"
      >Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()

const workflowListHeaders = [
  { title: "Workflow Type", key: "WorkflowTypeID", value: "WorkflowTypeID"},
  { title: "Assigned To", key: "AssignedUserName", value: "AssignedUserName" },
  { title: "Summary", key: "Summary", value: "Summary" },
  { title: "Due Date", key: "DueDate", value: "DueDate" },
  { title: "Requested By", key: "RequestedByUserName", value: "RequestedByUserName" },
  { title: "User", key: "UserName", value: "UserName" },
  { title: "Last Updated", key: "LastUpdated", value: "LastUpdated" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
var workflowList = ref([])
var workflowTypeLookup = ref([])

var search = ref(null)
var filteredItems = ref([])
var selectedWorkflowType = ref()

var showDialogAssignItem = ref(false)
var usersList = ref([])
var editItem = ref({})

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

watch(selectedWorkflowType, (newValue, oldValue) => {
  resetFilter()
})

function isOverdue(selectedDate) {
  const today = new Date()
  const dueDate = new Date(selectedDate)
  return (dueDate <= today)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}
function resetFilter() {
  filteredItems.value = workflowList.value.filter(item => {
    let isWorkflowTypeMatch = selectedWorkflowType.value ? item.WorkflowTypeID === selectedWorkflowType.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isWorkflowTypeMatch && textSearch
  })
}

async function getWorkflows() {
  await utils.httpRequest(`/Workflows/`, utils.REQUEST.GET, (data) => {
      workflowList.value = data
  })
  resetFilter()
}

async function getWorkflowTypes() {
  await utils.httpRequest(`/Workflows/Types`, utils.REQUEST.GET, (data) => {
      workflowTypeLookup.value = data
  })
  resetFilter()
}

function routeToUserProfile(userID) {
  router.push({
      name: "staff-profile",
      params: { id: userID },
    })
}

async function assignWorkflow() {
  let workflowNotificationID = editItem.value.WorkflowNotificationID
  showDialogAssignItem.value = false
  try {
    let payload = {
      UserID: editItem.value.UserID
    }
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Assign`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Assign Workflow|Successfully assigned")
    await getWorkflows()

  } catch (e) {
    updateResultMessage("warning|" + e.message + "|" + "Assign Workflow " + workflowNotificationID)
  }
}

async function sendReminder(workflowNotificationID) {
  await utils.httpRequest(`/Workflows/${workflowNotificationID}/Reminder`, utils.REQUEST.GET, (data) => {
    updateResultMessage("success|Reminder Status|" + data.Status)
  })
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

async function getUsers() {
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
    usersList.value = data.filter(item => item.IsActive == true)
  })
}

async function showAssignWorkflow(workflowNotificationID, assignedUserID) {
  await getUsers()
  editItem.value.WorkflowNotificationID = workflowNotificationID
  editItem.value.UserID = assignedUserID
  showDialogAssignItem.value = true
}

function cancelAssignWorkflow() {
  showDialogAssignItem.value = false
}

onMounted(async () => {
  let isAuthorized = (await authService.isHumanCapitalAdmin()) || (await authService.isHumanCapitalIT())

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view the Open Workflows")
    router.push({
      name: "not-authenticated"
    })
  }

  emit("updateBreadcrumb", ["Workflows", "Open"])
  emit("updateHeader", "Open Workflows")

  await getWorkflows()
  await getWorkflowTypes()
})
</script>