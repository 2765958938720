<template><div v-if="invalidDataCount > 0">
  <v-dialog v-model="dialogInvalidData" max-width="750px">
    <template v-slot:activator="{ props }">
      <v-badge
        v-bind="props"
        max="5"
        :content="invalidDataCount"
      >
        <v-icon size="large" color="warning">mdi-alert-box</v-icon>
      </v-badge>
      <v-tooltip
        activator="parent"
        location="top"
        >
        <div class="text-body-1 font-weight-bold">{{ invalidDataCount }} invalid items</div>
        <v-list
          density="compact"
          bg-color="surface-variant"
          variant="text"
        >
          <v-list-item v-for="(item, i) in invalidData">
            {{ item.InvalidDataPoint }} is {{ (item.InvalidDataValue === null || item.InvalidDataValue.length === 0) ? "empty" : "invalid" }}
          </v-list-item>
        </v-list>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5 text-primary">Invalid Information</v-card-title>
      <v-card-subtitle class="text-body-1 text-wrap">The following items are invalid and need to be rectified by editing the profile and correcting the information</v-card-subtitle>
      <v-card-text>
        <v-list
          density="compact"
          variant="text"
        >
          <v-list-item v-for="(item, i) in invalidData">
            {{ item.InvalidDataPoint }} is {{ (item.InvalidDataValue === null || item.InvalidDataValue.length === 0) ? "empty" : "invalid" }}
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeDialogInvalidData()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'

const props = defineProps({
  userID: [String, Number],
  autoUpdate: Boolean
})
const userID = ref(props.userID)
const autoUpdate = ref(props.autoUpdate || false)

const headers = [
  { title: "Invalid Data Point", key: "InvalidDataPoint", value: "InvalidDataPoint"},
]

const invalidData = ref([])
const invalidDataCount = ref(0)
const dialogInvalidData = ref(false)

watch(invalidData, (newValue) => {
  invalidDataCount.value = newValue.length
})

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}

async function refreshData() {
  await getInvalidData()
}
defineExpose({ refreshData });

function closeDialogInvalidData() {
  dialogInvalidData.value = false
}

async function getInvalidData() {
  console.log("Getting Invalid Data")
  await utils.httpRequest(`/Users/${userID.value}/InvalidData`, utils.REQUEST.GET, (data) => {
      invalidData.value = data
  })
}

onMounted(async () => {
  if (userID.value) {
    await getInvalidData()
    console.log("AUTOUPDATE: ", props.autoUpdate)
    if (autoUpdate.value) {
      setInterval(getInvalidData, 30000)
    }
  }
})
</script>
