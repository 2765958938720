import { createRouter, createWebHistory } from 'vue-router'
import authService from "@/services/authService";

import NotAuthenticated from '../views/NotAuthenticated.vue'

import NoProfile from '@/views/NoProfile.vue'
import StaffProfile from '../views/StaffProfile.vue'
import StaffProfileList from '../views/StaffProfileList.vue'
import NewStaffProfile from '../views/NewStaffProfile.vue'
import Lookups from '../views/admin/Lookups.vue'
import Roles from '../views/admin/Roles.vue'
import GlobalNotificationList from '../views/admin/GlobalNotificationList.vue'
import OpenWorkflowList from '../views/admin/OpenWorkflowList.vue'
import InvalidDataList from '../views/admin/InvalidDataList.vue'

import WorkFlowStaffNew from '../views/workflow/StaffNew.vue';
import WorkFlowStaffExit from '../views/workflow/StaffExit.vue';

const unmatched = '/:pathMatch(.*)*'
const unguarded = [
  '/',
  '/auth/redirect',
  '/not-authenticated',
]

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'not-authenticated' }
  },
  {
    path: '/not-authenticated',
    name: 'not-authenticated',
    component: NotAuthenticated
  },
  {
    path: '/no-profile/:id?',
    name: 'no-profile',
    component: NoProfile
  },
  {
    path: '/staff-profile/:id?',
    name: 'staff-profile',
    component: StaffProfile
  },
  {
    path: '/staff-list/',
    name: 'staff-list',
    component: StaffProfileList
  },

  {
    path: '/new-staff/',
    name: 'new-staff',
    component: NewStaffProfile
  },
  {
    path: '/workflow/staff-new-it/:id',
    name: 'workflow/staff-new-it',
    component: WorkFlowStaffNew
  },
  {
    path: '/workflow/staff-new-manager/:id',
    name: 'workflow/staff-new-manager',
    component: WorkFlowStaffNew
  },
  {
    path: '/workflow/staff-new-office/:id',
    name: 'workflow/staff-new-office',
    component: WorkFlowStaffNew
  },
  {
    path: '/workflow/staff-exit-manager/:id',
    name: 'workflow/staff-exit-manager',
    component: WorkFlowStaffExit
  },
  {
    path: '/workflow/staff-exit-office/:id',
    name: 'workflow/staff-exit-office',
    component: WorkFlowStaffExit
  },
  {
    path: '/workflow/staff-exit-it/:id',
    name: 'workflow/staff-exit-it',
    component: WorkFlowStaffExit
  },
  {
    path: '/lookups/:id?',
    name: 'lookups',
    component: Lookups
  },
  {
    path: '/roles/:id?',
    name: 'roles',
    component: Roles
  },
  {
    path: '/workflow/list-open/',
    name: 'workflow/list-open',
    component: OpenWorkflowList
  },
  {
    path: '/globalnotifications/',
    name: 'globalnotifications',
    component: GlobalNotificationList
  },
  {
    path: '/invaliddata-list/',
    name: 'invaliddata-list',
    component: InvalidDataList
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // guarded
  const guarded = unguarded.every(path => path !== to.path)
  if (guarded) {
    // authorised
    if (await authService.isAuthenticated() === true) {
      return next()
    } else {
      return next({ name: 'not-authenticated' })
    }
  } else {
    // unguarded
    next()
  }
})

export default router
