<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col></v-col>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedInvalidDataPoint"
                label="Data Point"
                density="compact"
                auto-select-first
                clearable
                :items="invalidDataPointLookup"
                :item-title="item => item.InvalidDataPoint"
                :item-value="item => item.InvalidDataPoint"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="1"><div class="d-flex justify-end">
              <v-switch
                v-model="selectedIsEmpty"
                variant="solo"
                label="Empty?"
                density="compact"
                color="primary"
              >
              </v-switch>
            </div></v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      v-model:search="search"
      :headers="usersInvalidDataListHeaders"
      :items="filteredItems"
      item-value="UsersInvalidDataPointID"
      items-per-page="50"
      >
      <template v-slot:item.UserID="{ item }">
        <v-btn
          v-if="item.EmailAddress"
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.EmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.EmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.StartDate="{ item }">
        {{ formattedDate(item.StartDate) }}
      </template>
      <template v-slot:item.InvalidDataPoint="{ item }">
        {{ item.InvalidDataPoint }} 
        <span v-if="item.InvalidDataValue">
          ({{ item.InvalidDataValue }})
        </span>
        <span v-else class="font-italic">
          (Empty)
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            @click="routeToUserProfile(item.UserID)"
          ><v-icon size="sm" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View {{ item.Name }}
            </v-tooltip>
          </v-btn>
          <v-btn v-if="showLookup(item.InvalidDataPoint, item.InvalidDataValue)"
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            @click="routeToLookup(item.InvalidDataPoint, item.InvalidDataValue, item.UserID)"
            ><v-icon icon="mdi-list-status" />
              <v-tooltip
                activator="parent"
                location="top"
              >{{ item.InvalidDataPoint }} Lookup
              </v-tooltip>
          </v-btn>
        </div>
      </template>
    </v-data-table>

  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()

const usersInvalidDataListHeaders = [
  { title: "User", key: "UserID", value: "UserID" },
  { title: "Start Date", key: "StartDate", value: "StartDate" },
  { title: "Invalid Data Point", key: "InvalidDataPoint", value: "InvalidDataPoint" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
var usersInvalidDataList = ref([])
var invalidDataPointLookup = ref([])

var search = ref(null)
var filteredItems = ref([])
var selectedInvalidDataPoint = ref(null)
var selectedIsEmpty = ref(false)

const managerLookups = ["ManagerProfile"]
const staffProfileLookups = ["StaffProfile"]

const rulesIsRequired = [v => !!v || "Value is required"]

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

watch(selectedInvalidDataPoint, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedIsEmpty, (newValue, oldValue) => {
  resetFilter()
})

function showLookup(lookupName, lookupValue) {
  const invalidDataPointLookupItem = invalidDataPointLookup.value.find((t) => t.InvalidDataPoint === lookupName).LookupID
  if (managerLookups.includes(invalidDataPointLookupItem)) {
    if (!lookupValue) {
      return false
    }
    else {
      return true
    }
  }
  else if (staffProfileLookups.includes(invalidDataPointLookupItem)) {
    return false
  }
  else {
    return true
  }
}

function routeToLookup(lookupName, lookupValue, userID) {
  const invalidDataPointLookupItem = invalidDataPointLookup.value.find((t) => t.InvalidDataPoint === lookupName).LookupID
  if (managerLookups.includes(invalidDataPointLookupItem)) {
    routeToUserProfile(lookupValue)
  }
  else if (staffProfileLookups.includes(invalidDataPointLookupItem)) {
    routeToUserProfile(userID)
  }
  else {
    const routeData = router.resolve({
        name: "lookups",
        params: { id: invalidDataPointLookupItem },
      })
    window.open(routeData.href, "_blank")
  }
}

function routeToUserProfile(userID) {
  const routeData = router.resolve({
      name: "staff-profile",
      params: { id: userID },
    })
  window.open(routeData.href, "_blank")
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}
function resetFilter() {
  filteredItems.value = usersInvalidDataList.value.filter(item => {
    let isInvalidDataPoint = selectedInvalidDataPoint.value ? item.InvalidDataPoint === selectedInvalidDataPoint.value : true
    let isEmpty = selectedIsEmpty.value ? item.InvalidDataValue === null : true
    return isInvalidDataPoint && isEmpty
  })
}

async function getInvalidData() {
  await utils.httpRequest(`/Users/InvalidData`, utils.REQUEST.GET, (data) => {
      usersInvalidDataList.value = data
  })
  resetFilter()
}

async function getStaticInvalidDataPoints() {
  await utils.httpRequest(`/Static/InvalidDataPoints`, utils.REQUEST.GET, (data) => {
      invalidDataPointLookup.value = data.sort(function(a, b) {
      var x = a["InvalidDataPoint"]
      var y = b["InvalidDataPoint"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
  resetFilter()
}

onMounted(async () => {
  let isAuthorized = (await authService.isHumanCapitalAdmin())

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view the Invalid Data List")
    router.push({
      name: "not-authenticated"
    })
  }

  emit("updateBreadcrumb", ["Invalid Data List"])
  emit("updateHeader", "Invalid Data List")

  await getStaticInvalidDataPoints()
  await getInvalidData()
})
</script>