import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import authService from '@/services/authService';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"
import './assets/css/main.css';

//Register Global Components
import datepicker from '@/components/DatePicker.vue';
import staffdetail from "@/components/StaffDetail.vue";
import staffdetailinvaliddatapanel from "@/components/StaffDetailInvalidDataPanel.vue"
import staffdocuments from "@/components/StaffDocuments.vue";
import notificationspanel from "@/components/NotificationsPanel.vue";
import roledetail from "@/components/RoleDetail.vue";
import birthdaylist from "@/components/BirthdayList.vue"
import linklist from "@/components/LinkList.vue"
import corevalues from "@/components/CoreValues.vue"
import globalnotifications from "@/components/GlobalNotifications.vue"
import xlsxexportbutton from "@/components/XLSXExportButton.vue"
import progressdialog from "@/components/ProgressDialog.vue"

//Register Global Lookup components
import lookupcountries from "@/components/lookups/Countries.vue";
import lookupcompanygroups from "@/components/lookups/CompanyGroups.vue";
import lookupcompensationtypes from "@/components/lookups/CompensationTypes.vue";
import lookupcompanies from "@/components/lookups/Companies.vue";
import lookupdepartments from "@/components/lookups/Departments.vue";
import lookupsilos from "@/components/lookups/Silos.vue";
import lookupoffices from "@/components/lookups/Offices.vue";
import lookupemployeetypes from "@/components/lookups/EmployeeTypes.vue";
import lookupcurrencies from "@/components/lookups/Currencies.vue";
import lookupgenders from "@/components/lookups/Genders.vue";
import lookupmaritalstatuses from "@/components/lookups/MaritalStatuses.vue";
import lookupbankaccounttypes from "@/components/lookups/BankAccountTypes.vue";
import lookupseatingtypes from "@/components/lookups/SeatingTypes.vue";
import lookupmealtypes from "@/components/lookups/MealTypes.vue";
import lookuplinks from "@/components/lookups/Links.vue";

await authService.initMsal(); /// Initialize MSAL when the app starts

var dynamicTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#002D62',
    secondary: '#739DD2',
    error: '#B71C1C',
    info: '#0053B3',
    success: '#2E7D32',
    warning: '#EF6C00',
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dynamicTheme',
    themes: {
      dynamicTheme,
    },
  }
})

createApp(App)
  .use(router)
  .use(VueCookies)
  .use(vuetify)

  .component('datepicker', datepicker)
  .component('staffdetail', staffdetail)
  .component('staffdocuments', staffdocuments)
  .component('staffdetailinvaliddatapanel', staffdetailinvaliddatapanel)
  .component('notificationspanel', notificationspanel)
  .component('roledetail', roledetail)
  .component('birthdaylist', birthdaylist)
  .component('globalnotifications', globalnotifications)
  .component('linklist', linklist)
  .component('corevalues', corevalues)
  .component('xlsxexportbutton', xlsxexportbutton)
  .component('progressdialog', progressdialog)

  .component('lookupcountries', lookupcountries)
  .component('lookupcompanygroups', lookupcompanygroups)
  .component('lookupcompensationtypes', lookupcompensationtypes)
  .component('lookupcompanies', lookupcompanies)
  .component('lookupdepartments', lookupdepartments)
  .component('lookupsilos', lookupsilos)
  .component('lookupoffices', lookupoffices)
  .component('lookupemployeetypes', lookupemployeetypes)
  .component('lookupcurrencies', lookupcurrencies)
  .component('lookupgenders', lookupgenders)
  .component('lookupmaritalstatuses', lookupmaritalstatuses)
  .component('lookupbankaccounttypes', lookupbankaccounttypes)
  .component('lookupseatingtypes', lookupseatingtypes)
  .component('lookupmealtypes', lookupmealtypes)
  .component('lookuplinks', lookuplinks)
  
  .mount('#app')
